
body{
	font-family: 'Poppins', sans-serif;
}
a{
	text-decoration: none;
}


.theme-banner-bg{ background-color: #5b438f; }

.theme-color{ color: #f6f2ff; }
.theme-color.dark{ color: #342457; }

.theme-bg{ background-color: #f6f2ff }
.theme-bg.dark{ background-color: #342457 }


.status-display{
	width: 100%;
	color: #fff;
	padding: 1rem 2rem;
	font-weight: bold;
}
.status-display.success{ background-color: #00d94c; }
.status-display.error{ background-color: #ff0040; }


.panel-topbar{
	padding: 7px 10px;
	box-shadow: 0px 10px 15px -3px rgba(52, 36, 87, 0.04);
	background-color: #5b438f;
}


.theme-cardify{
	padding: 45px;
	box-shadow: 0px 10px 15px -3px rgba(52, 36, 87, 0.1);
	border-radius: 14px;
}
.theme-lift{
	box-shadow: 0px 10px 15px -3px rgba(52, 36, 87, 0.1);
}
.theme-btn{
	padding: 0.5rem 2rem;
	font-size: 0.8rem;
	font-weight: bold;
	border: none;
	border-radius: 8px;
}
.theme-btn-primary{
	background-color: #006aff;
	color: #fff;
}



/** Headers */
.theme-header{
	font-size: 1.2rem;
	font-weight: bold;
	padding: 1rem 0rem;
	color: #8700b0;
}

.theme-header.header-sm{font-size: 1.2rem;}
.theme-header.header-nm{font-size: 1.4rem;}
.theme-header.header-md{font-size: 1.6rem;}
.theme-header.header-lg{font-size: 1.8rem;}
.theme-header.header-xl{font-size: 2rem;}



/** Section Titles */
.theme-section-title{
	margin: 0px;
	color: #8700b0;
	font-size: 1.6rem;
	font-weight: bold;
	padding: 0rem 1rem;
	border-left: 8px solid #8700b0;
}
.theme-section-title.title-lg{ font-size: 1.6rem; }
.theme-section-title.title-md{ font-size: 1.4rem; }
.theme-section-title.title-nm{ font-size: 1rem; }

.theme-section-title.title-light{ color: #f6f2ff; border-left: 8px solid #f6f2ff;}
.theme-section-title.title-dark{ color: #342457; border-left: 8px solid #342457;}



.theme-iconed-btn{
	background-color: #5b438f;
	color: #f6f2ff;
	border: none;
	padding: 4px 1rem;
	font-size: 0.85rem;
	margin: 0px 2px;
	padding: 10px 0px;
	border-radius: 4px;
}

.theme-iconed-btn.btn-sm{ width: 10rem; font-size: 0.75rem; }
.theme-iconed-btn.btn-md{ width: 14rem; font-size: 0.85rem; }
.theme-iconed-btn.btn-lg{ width: 18rem; font-size: 0.95rem; }




/** MAterial  Icons **/

.material-icons.icon-sm { font-size: 18px; }
.material-icons.theme-panel-icon-menu { font-size: 40px; }
.material-icons.theme-panel-icon-user { font-size: 32px; }



.theme-no-margin{margin: 0px;}



